import { WalletContextProvider } from './WallletContext';
import { WalletMultiButton, WalletDisconnectButton } from '@solana/wallet-adapter-react-ui';
import { FaSearch, FaWallet, FaBars } from 'react-icons/fa';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';

import './css/style.css'; // Assuming this will hold the provided styles plus adjustments
import React, { useMemo, useState } from 'react';
import MinimalMenu from "./components/MinimalMenu"
import logo from "./img/logo.jpg"
import Collections from './components/Collections'; // Import your Collections component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import sampleImage from "./img/AClass.jpeg"
import NFTChecker from './components/NFTChecker';
import FAQ from './components/FAQ';
import MintPage from './components/Mint';
type Collection = {
  image: string;
  title: string;
  soldPercentage: number;
  totalItems: number;
  soldItems: number;
  description: string;
};
function App() {
  const wallet = useWallet();
   // const { publicKey, sendTransaction, signTransaction } = useWallet();


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
  };
  let coll1: Collection= {image: sampleImage.toString(), title: "A Class", soldPercentage:2, totalItems: 1000, description: "A Class a Ticket to Metaverse", soldItems: 0 }
  //https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs
  let coll2: Collection = {image: "https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs", title: "Antropomorphic", soldPercentage:33, totalItems: 1000, description: "An antropomorphic god", soldItems: 330 }
  let sampleCollections: Collection[] = []
  sampleCollections.push(coll1)
  sampleCollections.push(coll2)

    // Define wallet adapters

    
  return (
    <ConnectionProvider endpoint="https://api.mainnet-beta.solana.com">
      <WalletProvider wallets={[]} autoConnect>
      <WalletContextProvider>
                <Router>
                    <div className="App">
                        <header className="menu-container">
                            <div className="logo">
                                <img src={logo} alt="Logo" className="logo-icon" />
                            </div>
                            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                                <a href="/collections">Collections</a>
                                <a href="/nft-holder">NFT Holder (Rewards)</a>
                                <a href="/faq">FAQ</a>
                                <a href="/support">Support</a>
                            </nav>
                            <div className="menu-icons">
                                <FaSearch className="icon" />
                                <FaWallet className="icon" />
                                <WalletMultiButton />
                                <FaBars className="hamburger" onClick={toggleMenu} />
                            </div>
                        </header>
                        <Routes>
                            <Route path="/collections" element={<Collections collections={sampleCollections} />} />
                            <Route path="/nft-holder" element={<NFTChecker />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/mint" element={<MintPage />} />
                        </Routes>
                    </div>
                </Router>
            </WalletContextProvider>
        </WalletProvider>
    </ConnectionProvider>
);
}

export default App;
