import React, { useState } from 'react';
import '../css/Mint.css';

const Mint: React.FC = () => {
  const [mintCount, setMintCount] = useState(1);
  const totalNFTs = 333;
  const mintedNFTs = 122; // Replace with dynamic data as needed
  const progress = (mintedNFTs / totalNFTs) * 100;

  const handleMint = () => {
    alert(`Minting ${mintCount} NFT(s)!`);
    // Add minting functionality here
  };

  return (
    <div className="mint-page-container-mint">
      <div className="header-mint">
        <img src="https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs" alt="Collection" className="collection-image-mint" />
        <div className="collection-info-mint">
          <h1 className="collection-title-mint">Antropomorphic</h1>
          <p className="collection-details-mint">Created on Thu, 31 Oct 2024 22:10:10 GMT by <a href="https://twitter.com/2bm4_EY" target="_blank" rel="noopener noreferrer">@QuaC</a></p>
          <div className="links-mint">
            <a href="https://sniper.xyz" target="_blank" rel="noopener noreferrer" className="market-link-mint">Sniper</a>
            <a href="https://magiceden.io" target="_blank" rel="noopener noreferrer" className="market-link-mint">ME</a>
            <a href="https://tensor.trade" target="_blank" rel="noopener noreferrer" className="market-link-mint">Tensor</a>
          </div>
        </div>
      </div>

      <div className="mint-info">
        <div className="progress-section-mint">
          <p>Total:</p>
          <div className="progress-bar-mint">
            <div className="progress-fill-mint" style={{ width: `${progress}%` }}></div>
          </div>
          <p className="progress-text-mint">{progress.toFixed(2)}% Sold ({mintedNFTs}/{totalNFTs})</p>
        </div>

        <div className="public-card-mint">
          <p>Status: <span className="status-active-mint">Active</span></p>
          <p>Max 555 mints per wallet (0)</p>
          <p>Current NFTs minted: {mintedNFTs}</p>
        </div>
      </div>

      <div className="mint-controls">
        <label htmlFor="mint-slider">Select Tokens Quantity:</label>
        <input
          type="range"
          id="mint-slider"
          min="1"
          max="10"
          value={mintCount}
          onChange={(e) => setMintCount(Number(e.target.value))}
        />
        <span className="mint-count">{mintCount}k</span>
        <br /><br />
        <button className="mint-button" onClick={handleMint}>Mint</button>
      </div>

      <div className="minted-nfts">
        <h2>Minted NFTs</h2>
        {/* Replace the following sample data with actual data */}
        <div className="nft-grid">
          {[...Array(6)].map((_, index) => (
            <div key={index} className="nft-card">
              <img src="https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs" alt={`Antropomorphic #${index + 1}`} />
              <p>Antropomorphic #{index + 1}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mint;
