import React from 'react';
import '../css/Collection.css';

type Collection = {
  image: string;
  title: string;
  soldPercentage: number;
  totalItems: number;
  soldItems: number;
  description: string;
};

type CollectionsProps = {
  collections: Collection[];
};

const Collections: React.FC<CollectionsProps> = ({ collections }) => {
  return (
    <div className="collections-container">
      <h2 className="collections-title">Latest</h2>
      <div className="collections-list">
        {collections.map((collection, index) => (
          <div key={index} className="collection-card">
            <a href='/mint'>
            <img src={collection.image} alt={collection.title} className="collection-image" />
            <div className="collection-details">
              <h3 className="collection-title">{collection.title}</h3>
              <div className="progress-bar">
                <div
                  className="progress"
                  style={{ width: `${collection.soldPercentage}%` }}
                ></div>
              </div>
              <div className="sold-info">
                <span>{collection.soldPercentage.toFixed(2)}% Sold</span>
                <span>{collection.soldItems}/{collection.totalItems}</span>
              </div>
              <p className="collection-description">{collection.description}</p>
            </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collections;
