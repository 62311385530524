import '../css/NFTChecker.css';
import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';
import allCNFTs from "./allcNFTs.json"
import Cookies from 'js-cookie'; // You can use js-cookie for easier cookie management

import { ConnectionProvider, WalletProvider, useConnection, useWallet, WalletContextState } from '@solana/wallet-adapter-react';

const NFTChecker: React.FC= () => {
  
  const wallet = useWallet();

  const [validcNFTsI, setValidCNFTsI]= useState(0)

  const [nftCount, setNftCount] = useState<number | null>(null);
  
  interface DataAPI {
    wallet: string,
    cNFTsCollection: string,
  }

  interface DatacNFT {
    cNFTs: string[];
  }
  interface PostData {
 
    wallet: string;
    cNFTsCollection: string;
  }
  const getValidCNFTs = useCallback(async () => {
    console.log("wallet2"+wallet.publicKey)

    
    try {
      //        if (!wallet2.connected) {

        if (!wallet.connected) {
            alert('Wallet not connected');
            return;
        }
       /*  if (!wallet.signMessage) {
            alert('Please sign message!');
            return;
        } */

      /*   const message = new TextEncoder().encode('Please sign this message to confirm your identity.');
        const signatureArrayBuffer = await wallet.signMessage(message);
        const signatureBase58 = bs58.encode(Buffer.from(signatureArrayBuffer));
        setSignature(signatureBase58);

        const walletPublicKey: PublicKey = wallet.publicKey!;
        setPublicAddress(walletPublicKey.toString());

        console.log(`Signature: ${signatureBase58}`);
        console.log(`Public Address: ${walletPublicKey.toString()}`); */

        const apiUrl = 'https://getcnfts-sharboqboq-uc.a.run.app';
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /p/1
        let slugA = "";
     /*    if (currentpathName.startsWith("/animal/")){
           slugA = currentpathName.substring(8, currentpathName.length)
          console.log("slug: "+slugA)
        } */
        const exampleData: DataAPI = {
            wallet:  wallet.publicKey+"",
            cNFTsCollection: "all"
          };
    
        // Call the function
        //await postData(apiUrl, exampleData);
        

        postData(apiUrl, exampleData).then(() => {
          //navigate('/animal/'+slugA);

        });

    } catch (error) {
        console.error('Signing error:', error);
       // alert('Failed to sign message!');
    }
},  [wallet]);
  const checkNFTs = () => {
    // Mock function to simulate fetching NFT count. Replace this with actual API call.
    const fetchedNFTCount = Math.floor(Math.random() * 10); // Random number for demonstration
    setNftCount(fetchedNFTCount);
  };


  const postData = async (url: string, data: PostData): Promise<void> => {
    try {
      const response = await fetch(url, {
        method: 'POST', // Method type
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data) // Convert the TypeScript object into a JSON string
      });
  
      // Check if the request was successful
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // You can process the response further if needed
      const result = await response.json();
    
      let counterValidI= 0
      const officialAllCNFTs2: DatacNFT = allCNFTs;
/* 
      for (var i =0; i<result.length; i++){
        //console.log("cNFTs: "+ result[i].id)
        for (var e=0; e<officialAllCNFTs2.cNFTs.length; e++){
// console.log(officialAllCNFTs[e]+"")
          if(result[i].id==officialAllCNFTs2.cNFTs[e]){
            console.log("valid")
counterValidI+=1;
          }
        }
      } */

    
      // set Hold cNFTs data
      const docData = {
        cNFTs: result.length,
    
    };
    //await updateDoc(doc(db, "users", uid), docData);
    

      setValidCNFTsI(result.length)
      Cookies.remove('validCNFTs');

      Cookies.set('validCNFTs_'+data.cNFTsCollection, result.length+"", { expires: 21 }); // Expires in 21 days

      console.log('Success:', result);
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div className="nft-checker-container">
      <h2 className="nft-checker-title">NFT Holder Checker</h2>
      <button onClick={getValidCNFTs} className="check-button">
        Check How Many NFTs I Hold
      </button>
      {validcNFTsI !== null && (
        <p className="nft-count">You hold {validcNFTsI} valid NFTs.</p>
      )}
      <button
        className="claim-button"
        disabled={nftCount === null || nftCount === 0}
      >
        Claim Tokens
      </button>
    </div>
  );
};

export default NFTChecker;
